/** @format */
import _Footers from './Footers';
import _Footer2 from './Footers/footer2';
import _LandingPageHeader from './Header/LandingPageHeader';
import _HomeHeader from './Header/HomeHeader';
import _Register from './Register';
import _RegisterPageContent from './Content/RegisterPageContent';
import _TransferPageContent from './Content/TransferPageContent';
import _ExamplesNavbar from './Navbars/ExamplesNavbar';
import _StaticNavbar from './Navbars/StaticNavBar';
import _CardInfo from './CardInfo';
import _MemberNavbar from './Navbars/MemberNavbar';
import _ProfileHeader from './Header/ProfileHeader';
import _PaymentNavbar from './Navbars/PaymentNavbar';
import _ProfilePageHeader from './Header/ProfilePageHeader';

export const LandingPageHeader = _LandingPageHeader;
export const ProfilePageHeader = _ProfilePageHeader;
export const RegisterPageContent = _RegisterPageContent;
export const TransferPageContent = _TransferPageContent;
export const DemoFooter = _Footers;
export const Footer2 = _Footer2;
export const ExamplesNavbar = _ExamplesNavbar;
export const StaticNavbar = _StaticNavbar;
export const CardInfo = _CardInfo;
export const MemberNavbar = _MemberNavbar;
export const HomeHeader = _HomeHeader;
export const ProfileHeader = _ProfileHeader;
export const Register = _Register;
export const PaymentNavbar = _PaymentNavbar;
