/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import axios from 'axios';
import {Link} from 'react-router-dom';
//import api url
import API_URL from  './../../../ApiUrl';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import SweetAlert from 'sweetalert2-react';
// core components
//import ExamplesNavbar from "./../components/molecules/Navbars/ExamplesNavbar.js";
//import LandingPageHeader from "./../components/molecules/Headers/LandingPageHeader.js";
//import DemoFooter from "./../components/molecules/Footers";

import { ProfilePageHeader, ExamplesNavbar, DemoFooter, Register } from './../../../components/molecules/';
import { grey } from "ansi-colors";
import classnames from "classnames";
import Carousel from 'react-multi-carousel';



class LandingPage extends Component {
  state = {
    nama_lengkap:"",
    no_telp:"",
    email:"",
    id_propinsi:"",
    id_kabkota:"",
    message:"Error !",
    error: {},
    showSwal: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataPropinsi: [],
    dataKabkota: [],
    dataKabkotafilter:[],
    aktifKonten:[],
    checkemail:"",
    pesanEmailError:"",
  } 
  handleValidation(){
    let errors = {};
    let formIsValid = true;
    //nama
    if(!this.state.nama_lengkap){
        formIsValid = false;
        errors.nama_lengkap = "Nama wajib diisi";
        this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
        formIsValid = false;
        errors.no_telp = "No telepon wajib diisi";
        this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
        formIsValid = false;
        errors.email = "Email wajib diisi";
        this.inputEmail.style = this.state.inputBorder;
    }
    if(typeof this.state.email !== "undefined"){
        let lastAtPos  = this.state.email.lastIndexOf('@');
        let lastDotPos = this.state.email.lastIndexOf('.');
        if(!(lastAtPos < lastDotPos && lastAtPos > 0 && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)){
            formIsValid = false;
            errors.email = "Email tidak valid";  
            this.inputEmail.style = this.state.inputBorder;  
        }
    }
    if(this.state.checkemail === "Tolak"){
      formIsValid = false;
      errors.email = this.state.pesanEmailError;
      this.inputEmail.style = this.state.inputBorder;
  }
     //pripinsi
     if(!this.state.id_propinsi){
      formIsValid = false;
      errors.id_propinsi = "Provinsi wajib diisi";
      this.inputPropinsi.style = this.state.inputBorder;
    }
    //kabkota
    if(!this.state.id_kabkota){
      formIsValid = false;
      errors.id_kabkota = "Kabupaten / Kota wajib diisi";
      this.inputKabKota.style = this.state.inputBorder;
    }
    this.setState({error:errors});
    return formIsValid;
  }

  onChangeEmail = (e) => {
    this.setState({email:e.target.value})
    this.checkEmail();   
  }

  registrationSubmit(e){
    e.preventDefault();
    // if(this.handleValidation()){
    //   this.sendData();
    // }
      if(this.handleValidation()){
          this.sendData();
      }else{
          this.setState({
              formAlert:{display:'unset'}, 
              formAlertClassName:'alert alert-warning',
              message:"Error !"
          })
      }
  }

  sendData(){
    this.setState({
      message:"Proses simpan, mohon menunggu beberapa saat.",
      formAlert:{display:'block'},
      formAlertClassName:'alert alert-warning',
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('nama_lengkap',this.state.nama_lengkap);
    formData.append('no_wa',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('id_propinsi',this.state.id_propinsi);
    formData.append('id_kabkota',this.state.id_kabkota);
  
    axios({
      method: 'post',
      url:API_URL.url+'/peserta/register',
      data:formData,
      headers: {
        'Accept': 'application/json'
      }
    }).then((reply)=>{
        //console.log(reply)
        if(reply.data[0].id_register !== undefined){
          this.setState({showSwal:true})
        }else{
          // alert(reply.data);
          this.setState({btnDisabled:false})
          // this.hideAlert();
          alert("Pendaftaran Berhasil!")
          window.location.reload();
        }
    }).catch(function(reply){
        console.log(reply)
    });
  }

  onBlurNamaLengkap(e){
    e.preventDefault()
    if(this.state.nama_lengkap.length > 2){
        this.inputNamaLengkap.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.nama_lengkap = '';                                 
            return { error };                   
        })
    }
  }
  onBlurNoTelp(e){
      e.preventDefault()
      if(this.state.no_telp.length > 0){
          this.inputNoTelp.style = "";
          this.setState(prevState => {
              let error = Object.assign({}, prevState.error);  
              error.no_telp = '';                                 
              return { error };                   
          })
      }else{
        this.inputNoTelp.style = this.state.inputBorder;
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.error);  
            errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
            this.setState({error:errors});                  
        })
      }
  }
  onBlurEmail(e){
    e.preventDefault()
    this.checkEmail();
    if(this.state.email.length > 5){
        this.inputEmail.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.email = '';                                 
            return { error };                   
        })
    }
  }
  onBlurPropinsi(e){
    e.preventDefault()
    if(this.state.id_propinsi.length > 2){
        this.inputPropinsi.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_propinsi = '';                                 
            return { error };                   
        })
    }
  }
  onBlurKabkota(e){
    e.preventDefault()
    if(this.state.id_kabkota.length > 2){
        this.inputKabKota.style = "";
        this.setState(prevState => {
            let error = Object.assign({}, prevState.error);  
            error.id_kabkota = '';                                 
            return { error };                   
        })
    }
  }

  getDataPropinsi(){
    const url = API_URL.master+'/propinsi';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataPropinsi: data })
        console.log(this.state.dataPropinsi)
    })
  }

  getDataKabkota(){
    const url = API_URL.master+'/kabkota';
    axios.get(url,{
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.masterKey}`
        }
    }).then(response => response.data)
    .then((data) => {
        this.setState({ dataKabkota: data })
        console.log(this.state.dataKabkota)
    })
  }

  setPropinsi(e){
    e.preventDefault();
      this.setState({
          id_propinsi:e.target.value
      })

      const data = Object.values(this.state.dataKabkota);
      const id = e.target.value;
      const filterData = data.filter(dataFilter => {
        return dataFilter.id_propinsi == id;
        });
      this.setState({dataKabkotafilter:filterData})
      console.log(filterData)
  }

  setKabkota(e){
    e.preventDefault();
      this.setState({
          id_kabkota:e.target.value
      })
      console.log(this.state.id_kabkota)
  }

  radioChangeHandler = (event) =>{
    this.setState({
        konsultan:event.target.value
    })
  }
  componentDidMount() {
    this.getDataPropinsi();
    this.getDataKabkota();
  }

  checkEmail(){
    const url = API_URL.url+'/peserta/cekmail';

    var formData = new FormData();
    formData.append('email',this.state.email);
    axios({
        method: 'post',
        url: url,
        data:formData,
        headers: {
          'Accept': 'application/json'
        }
       
    }).then(response => response.data)
    .then((data) => {
        this.setState({ checkemail: data[0].status, pesanEmailError: data[0].pesan })
        // console.log('cek data email', this.state.checkemail)
        // console.log('cek pesan email', this.state.pesanEmailError)
        console.log('cek email1', this.state.pesanEmailError)
    })
  }

  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
    this.inputNamaSekolahlah.value = "";
    this.inputKelasSemester.value = "";
    this.inputPassword.value = "";
    this.selectPropinsi.value = "";
    this.selectKabkota.value = "";
    this.inputRePassword.value = "";
    this.inputKodePromo.value = "";
  }

  render() {
 
  return (
    <>
      <ExamplesNavbar />
      <ProfilePageHeader />

    <div className="main">
      <div className="section" style={{ backgroundColor: ''}}>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className="icons-container" style={{ fontFamily:'' }}>
                <div>
                  <h3 className="headtitle-left mb-5">Alasan Memilih Perth sebagai<br />
                  Tujuan Kuliah
                  </h3>
                  <p className="desc-left">
                  Bayangkan kamu belajar di kota yang bukan hanya sebagai 
                  tempat terbaik untuk tinggal tetapi juga merupakan salah 
                  satu tujuan yang sempurna untuk memulai karir kamu.
                  <br/><br/>
                  Perth adalah salah satu tujuan di mana kamu bisa 
                  mendapatkan pendidikan kelas dunia dari institusi 
                  pendidikan terbaik dunia. Terlepas dari iklim Mediterania, 
                  pemandangan indah dan pantai berangin, Perth juga 
                  menarik karna menwarkan pengalaman belajar yang ideal 
                  bagi banyak siswa internasional dari latar belakang budaya 
                  yang berbeda dengan fasilitas pendidikan yang excellent. 
                  <br/><br/>
                  Sebagai siswa internasional, kamu akan mendapatkan 
                  diskon hingga 40% untuk semua transportasi umum di 
                  seluruh Australia Barat!
                  </p>
                </div>
              </div>
              <div className="icons-container mt-5" style={{ fontFamily:'' }}>
                <div>
                  <h3 className="headtitle-left mb-5">Western Australia Education<br />
                  Festival
                  </h3>
                  <p className="desc-left">
                  NIEC Indonesia menyelenggarakan pameran pendidikan 
                  online untuk membantu kamu membuat keputusan terbaik 
                  tentang belajar di Perth, Australia Barat.
                  <br/><br/>
                  Pameran ini dirancang untuk membantu kamu memahami 
                  mengapa institusi di Perth seperti Edith Cowan University, 
                  Edith Cowan College, National Institute of Technology, 
                  Australian Professional Skills Institute, dan TAFE 
                  International Western Australia adalah beberapa tempat 
                  belajar yang terbaik di Australia. Perwakilan dari kampus 
                  ternama ini akan menginformasikan tentang jadwal 
                  pendaftaran kampus, program populer, kesempatan bekerja 
                  di Australia setelah selesai kuliah, fasilitas kampus, dan 
                  banyak lagi.
                  <br/><br/>
                  Selain itu, kamu juga dapat melihat pilihan akomodasi yang 
                  tersedia di Perth dengan berinteraksi dengan perwakilan 
                  dari ‘The Student Housing Company’.
                  </p>
                </div>
              </div>
 
            </Col>
            <Col lg="6" md="6" style={{ backgroundColor: '#c3242c'}}>
              <Register />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section" style={{ backgroundColor: '#E6E6E6', color:"black"}} align="center">
      
          <Col lg="12" md="12">
              <p className="box-black">
              Menemukan tempat tinggal di Perth sebagai mahasiswa internasional sangatlah mudah dengan 
              Student Housing Company. Mereka adalah bagian dari “Global Student Accommodation” dan 
              berfokus untuk menawarkan layanan terbaik kepada siswa. Misi mereka adalah untuk menyediakan 
              tempat yang aman dan nyaman di dekat institusi mereka, yang membantu mereka untuk dengan 
              mudah pergi ke kampus.
              </p>
            </Col>
            <Container>
         <Col lg="12" md="12" >
            <div className="icons-container" style={{ fontFamily:'' }}>
              <div>
                <h3 className="headtitle mb-5 mt-5">Serunya Tinggal di Perth ? 
                </h3>
              </div>
            </div>
        </Col>
        <div className="col-12 icons-container" style={{ fontFamily:'' }}>
              <Row>
                <Col md="4" sm="12" className="mt-5 mb-5">
                    <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                    alt="..."
                    src={require("../../../assets/img/perth/perth-01.png")}
                    className="responsiveImg"
                    />
                        <p className="desc-red">
                        Western Australia adalah provinsi dengan ekonomi terkuat di Australia 
                        yang berarti kalian akan mendapatkan banyak sekali 
                        kesempatan untuk bekerja paruh waktu dan juga berkarir setelah lulus kuliah.
                        </p>
                </Col>
                <Col md="4" sm="12" className="mt-5 mb-5">
                <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                    alt="..."
                    src={require("../../../assets/img/perth/perth-02.png")}
                    className="responsiveImg"
                    />
                        <p className="desc-red">
                        Siswa yang kuliah di Western Australia, 
                        akan mendapatkan keuntungan untuk tinggal dan berkarir 
                        dengan menggunakan “Post Study Work Steam” selama 3 tahun 
                        </p>
                </Col>
                <Col md="4" sm="12" className="mt-5 mb-5">
                <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                    alt="..."
                    src={require("../../../assets/img/perth/perth-03.png")}
                    className="responsiveImg"
                    />
                        <p className="desc-red">
                        Perth adalah kota ke-6 terbaik untuk tinggal di dunia 
                        (Economist Intelligence Uni 2021)
                        </p>
                </Col>
              </Row>
              <Row>
                <Col md="6" sm="12" className="mt-5 mb-5">
                <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                    alt="..."
                    src={require("../../../assets/img/perth/perth-04.png")}
                    className="responsiveImg"
                    />
                        <p className="desc-red">
                        Perth adalah salah satu kota paling aman untuk tinggal di dunia
                        (Travelsafe-abroad.com/australia/perth)
                        </p>
                </Col>
                <Col md="6" sm="12" className="mt-5 mb-5F">
                <img style={{ maxWidth:'350px', maxHeight:'260px' }}
                    alt="..."
                    src={require("../../../assets/img/perth/perth-05.png")}
                    className="responsiveImg"
                    />
                        <p className="desc-red">
                        Perth adalah kota yang paling ramah di dunia 
                        (source: shorturl.at/fgyFU)
                        </p>
                </Col>
              </Row>
            </div>
            
        </Container>
      </div>

      <div className="section" style={{ backgroundColor: '', color:"black"}} align="center">
        <Container>
        <Row>
            <Col lg="12" md="12" >
                <div className="icons-container" style={{ fontFamily:'' }}>
                  <div>
                    <h3 className="headtitle mb-5">Institusi yang Berpartisipasi <br />
                      di Western Australia Education Festival
                    </h3>
                  </div>
                </div>
            </Col>
            <Col lg="12" md="12">
            <img
                alt="logo Western Australia"
                src={require('./../../../assets/img/niec-logo/institution-collab.png')}
                style={{ maxHeight: '80%', maxWidth: '100%',marginTop:'50px' }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section" style={{ backgroundColor: '#E6E6E6', color:"black"}} align="center">
        <Container>
        <Col lg="12" md="12" >
            <div className="icons-container" style={{ fontFamily:'' }}>
              <div>
                <h3 className="headtitle mb-5">Kenapa Harus Hadir di Acara <br />
                  Western Australia Education Festival ? 
                </h3>
              </div>
            </div>
        </Col>
        </Container>
        <div className="section2" style={{ fontFamily:'' }}>
        <Container>
              <Row>
                <Col md="4" sm="12" className="mt-5 mb-5 box">
                    <img style={{ maxWidth:'120px', maxHeight:'170px' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-01.png")}
                    />
                        <p className="desc-box">
                          Tonton Pameran langsung kami dan 
                          dapatkan wawasan tentang institusi 
                          seperti ECU, ECC, NIT, APSI, dan TIWA 
                          dari kenyamanan rumah kamu
                        </p>
                </Col>
                <Col md="4" sm="12" className="mt-5 mb-5 box">
                    <img style={{ maxWidth:'120px', maxHeight:'170px' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-02.png")}
                    />
                        <p className="desc-box">
                        Berinteraksi dengan 'The Student 
                        Housing Company' dan jelajahi fasilitas 
                        akomodasi yang ditawarkan Perth 
                        kepada kamu
                        </p>
                </Col>
                <Col md="4" sm="12" className="mt-5 mb-5 box">
                    <img style={{ maxWidth:'120px', maxHeight:'170px' }}
                    alt="..."
                    src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-03.png")}
                    />
                        <p className="desc-box">
                        Terlibat dalam sesi tanya jawab dengan 
                        perwakilan dari berbagai kampus
                        </p>
                </Col>
              
                  <Col md="6" sm="12" className="mt-5 mb-5 box-center">
                      <img style={{ maxWidth:'120px', maxHeight:'170px' }}
                      alt="..."
                      src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-04.png")}
                      />
                          <p className="desc-box">
                          Dapatkan informasi tentang jadwal 
                          pendaftaran kampus dan juga informasi 
                          beasiswa
                          </p>
                  </Col>
                  <Col md="6" sm="12" className="mt-5 mb-5 box-center">
                      <img style={{ maxWidth:'120px', maxHeight:'170px' }}
                      alt="..."
                      src={require("../../../assets/img/logo_ilustrasi_bennefit/icon-05.png")}
                      />
                          <p className="desc-box">
                          Dapatkan bimbingan dari para ahli 
                          konsultan pendidikan untuk jurusan 
                          yang paling sesuai dengan minat dan 
                          bakat kamu
                          </p>
                  </Col>
                </Row>
            </Container>
            </div>
      </div>

      <div className="section" style={{ backgroundColor: ''}} align="center">
        <Container>
            <Row>
            <Col lg="12" md="12" >
                <div className="icons-container" style={{ fontFamily:'' }}>
                  <div>
                    <h3 className="headtitle mb-5">Tentang NIEC Indonesia</h3>
                  <p className="desc">
                  NIEC adalah kantor konsultan pendidikan dengan pengalaman lebih dari 10 tahun telah 
                  memberangkat ribuan siswa untuk kuliah di luar negeri. NIEC telah bekerja sama dengan lebih dari 
                  100 kampus di puluhan negara di dunia
                  </p>
                  <br />
                  </div>
                </div>
            </Col>
            <Col lg="12" md="12">
            <img
                alt="logo Western Australia"
                src={require('./../../../assets/img/niec-logo/western-logo.png')}
                style={{ maxHeight: '40%', maxWidth: '80%',marginTop:'100px' }}
              />
            </Col>
            </Row>
          </Container>
        </div>



</div>      

      <DemoFooter />
    </>
  );
            }
}

export default LandingPage;
