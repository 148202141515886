import React, { Component } from "react";
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';


const Logout =  props => {
    const { history } = props;
  
    let cookies = new Cookies();
    let aaa = cookies.get('token');
    //console.log(aaa);
     cookies.set('token','','/');
    // history.push('/');

      localStorage.clear();
      window.location.href = '/';

    // return (
    //   <div style={{color:'#fff!important'}}>
    //     {{aaa}}
    //   </div>
    // );
};

Logout.propTypes = {
    history: PropTypes.object
  };
export default Logout;