// const url = "http://103.41.205.45:1951";
// const master = "http://103.41.205.45:1936";
// const membership = "http://103.41.205.45:36";
// const isMakassarKey = 'KIEtvrCBf1jB8cdbwTrZNJPheue8Ta80';
// const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';

const url = "https://westernapi.niecindonesia.com";
const master = "https://api2.niecindonesia.com";
// const membership = "https://participant.niecindonesia.com";
// const membershipKey = 'FYzev0v4c2wLHoewHjLQHps1WxnhLgVB';
const API_URL = {
    url,
    master
}

export default API_URL;

// export default api_url;