/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

function ExamplesNavbar() {
  
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  // const [brandLogo, setBrandLogo] = React.useState("Logo_NIEC_large.png");
  const [brandLogo, setBrandLogo] = React.useState("logo192.png");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const [displayNone, setDisplayNone] = React.useState("none");

  const lalala = require(`./../../../assets/img/niec-logo/${brandLogo}`);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    const updateBrandLogo = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
        ) {
          setDisplayNone("");
          // setBrandLogo("Logo_NIEC_large.png");
          setBrandLogo("logo192.png");
        } else if (
          document.documentElement.scrollTop < 300 ||
          document.body.scrollTop < 300
        ) {
          setDisplayNone("none");
          // setBrandLogo("Logo_NIEC_large.png");
          setBrandLogo("logo192.png");
        }
      };

    window.addEventListener("scroll", updateNavbarColor);
    window.addEventListener("scroll", updateBrandLogo);



    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    // <Navbar style={{backgroundColor:"rgb(248, 248, 248)"}}
    <Navbar style={{backgroundColor:"#000407"}}
      className={classnames("fixed-top navbarGradient", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
            target="_blank"
            title="Coded by Creative Tim"
            tag={Link}
            style={{padding:"5px"}}
          >
          <img style={{ height:'100', width:'100' }}
            alt="..."
            className={classnames("limonasi-logo", displayNone)}
            src={lalala}
          />
          </NavbarBrand>

          {/* <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button> */}
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              {/* <NavLink style = {{ color: 'black' }} to="/login" tag={Link}>Login
              </NavLink> */}
            </NavItem>
            {/* <NavItem>
              <NavLink style = {{ color: 'white' }}
                //href="http://localhost:3001/#formRegis"
                to="/register" 
                tag={Link}
              >Register
              </NavLink>
            </NavItem> */}
           
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
